let slider, title, text,  initial, maximum, time
const images = []
const labels = []

function preLoadImages () {
    document.querySelectorAll('[slider-image]').forEach(
        (el, i) => {
            images[i] = new Image()
            images[i].src = el.getAttribute('slider-image')
            labels[i] = el.children
        }
    )
}

function loadImage (img) {
    slider.style.backgroundImage=`url("${images[img].src}")`
    title.innerHTML = labels[img][0].textContent
    text.innerHTML = labels[img][1].textContent
}

function exchangeImage (){
    initial++;
    if(initial > maximum){
        initial = 0;
    }
    loadImage(initial);
}

function start () {
    preLoadImages()
    slider = document.getElementById('slider')
    title = document.getElementById('slider-title')
    text = document.getElementById('slider-text')
    initial = 0
    maximum = images.length - 1
    loadImage(initial)
    time = setInterval(exchangeImage , 8000)
}

window.addEventListener('load',  start)